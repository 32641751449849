'use strict';

/**
 * Idle Timer and Client Expiration Class
 *
 * @param   {Object} options - Object of modal options
 */
export default class FastFundIdle {
  //---------------------------------------------------------------------------------------------------------
  // Static Variables
  //---------------------------------------------------------------------------------------------------------
  static CONTENT = `
    This session has been locked for your security and privacy due to inactivity. Click 'OK' to resume or 'CANCEL' to sign out.
    <div style="margin: 2rem 1rem">
      <form id="expiration_form" class="ff-form">
        <div class="icon-wrapper">
          <i class="far fa-lock-alt icon-left"></i>
          <input value="" autocomplete="current-password" required="required" minlength="3" maxlength="25" placeholder="Password" class="icon-left" type="password" name="password" id="password">
        </div>
      </form>
    </div>
  `.trim();

  //---------------------------------------------------------------------------------------------------------
  // Static Functions
  //---------------------------------------------------------------------------------------------------------

  // Returns the DOM events that indicate user activity
  static get events() {
    return ['click', 'keypress', 'keydown', 'mousedown', 'mousemove', 'mousewheel', 'scroll', 'touchstart', 'wheel'];
  }

  //---------------------------------------------------------------------------------------------------------
  // Main Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Constructor
   *
   * @param   {Integer}   timeout   - Timeout in minutes before a ping request is sent
   */
  constructor(timeout) {
    this.dialogHTML = null;                                      // expiration dialog content
    this.dialog     = null;                                      // expiration dialog object
    this.interval   = null;                                      // Interval ID
    this.expireTime = timeout * 60 * 1000;                       // time allowed before expiration in MS
    this.idleTime   = this.expireTime / 4;                       // time between idle checks in MS
    this.running    = false;                                     // timer running flag
    this._idleCheck = this._idleCheck.bind(this);
    this.touch      = this.touch.bind(this);

    if (/portal/.test(window.location.href)) {
      this.portal     = true;
      this.pingURL    = '/portal/ping';
      this.resumeURL  = '/portal/expire';
    }
    else {
      this.portal     = false;
      this.pingURL    = '/ping';
      this.resumeURL  = '/resume';
    }

    this.start();
  }

  /**
   * Open the expiration dialog
   */
  _openDialog() {
    const self = this;

    if (this.portal) {
      this.dialog = new FastFundModal({
        close:    false,
        private:  true,
        title:    'FastFund Session Expired',
        content:  `Your session has expired due to inactivity.`,
        onClose:  ()=>{ location.href = self.resumeURL }
      });
    }
    else {
      this.dialog = new FastFundModal({
        close:    false,
        private:  true,
        title:    'FastFund Session Expired',
        content:  FastFundIdle.CONTENT,
        buttons:  [
          {
            label: 'ok',
            callback: modal => {
              const form = ff.get('#expiration_form');

              if (form && form.reportValidity()) {
                const pwd   = ff.get('input[type=password]', form).value;
                const token = new Date().getTime();

                ff.fetch(self.resumeURL, {
                  method:     'POST',
                  data:       {password: pwd, token: token},
                  onSuccess:  data => {
                    if (data.token == token) {
                      ff.flash('info', 'Session resumed.');
                      self.start();
                      self.ping();
                      modal.close();
                    }
                    else ff.fetch(self.resumeURL, {method: 'POST'});
                  }
                });
              }
            }
          },
          {
            label:    'cancel',
            callback: data => { ff.fetch(self.resumeURL, {type: 'POST'}) }
          }
        ]
      });
    }
  }

  /**
   * Bind events that trigger client activity
   */
  _bindEvents() {
    FastFundIdle.events.forEach(event => {
      // add a listener for each event, use capture for scroll, otherwise bubble (default)
      window.addEventListener(event, this.touch, event == 'scroll');
    });
  }

  /**
   * Unbind events that trigger client activity
   */
  _unbindEvents() {
    FastFundIdle.events.forEach(event => {
      // remove a listener for each event, use capture for scroll, otherwise bubble (default)
      window.removeEventListener(event, this.touch, event == 'scroll');
    });
  }

  /**
   * Returns true if idle time has exceeded the timeout
   */
  _expired() {
    // if current time - last timestamp is greater than timeout, the client is expired
    const diff = Date.now() - (localStorage.timestamp || Date.now());
    return (diff > this.expireTime);
  }

  /**
   * Callback for the interval timer, check if the client is idle
   */
  _idleCheck() {
    if (!sessionStorage.expired) {
      if (this._expired()) this.expire();
      else this.ping();  // idleTime elapsed, client is idle but not yet expired, ping the backend
    }
    else this.expire();  // re-expire
  }

  //---------------------------------------------------------------------------------------------------------
  // Public Functions
  //---------------------------------------------------------------------------------------------------------

  /**
   * Expire a client and display the expired dialog
   */
  expire() {
    // Open the expired dialog
    this._openDialog();

    // Set global store expired flags
    localStorage.expired   = 1;
    sessionStorage.expired = 1;
    this.stop();
  }

  /**
   * Starts the idle timer and binds events
   */
  start() {
    if (!this.running) this._bindEvents();
    this.interval = setInterval(this._idleCheck, this.idleTime);
    this.running  = true;
    this.touch();
  }

  /**
   * Stops the idle timer and unbinds events
   */
  stop() {
    if (this.running) this._unbindEvents();
    clearInterval(this.interval);
    this.running = false;
  }

  /**
   * Ping the backend
   */
  ping() {
    ff.fetch(this.pingURL, {method: 'GET'});
  }

  /**
   * Update the global store timestamp to indicate client activity
   */
  touch() {
    localStorage.timestamp = Date.now();
    localStorage.removeItem('expired');
    sessionStorage.removeItem('expired');
  }

}
